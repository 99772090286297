import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store";
import { Pagination, Payout, UserModel } from "../../sdk/Types";
import admin from "../../sdk";

export interface PayoutsState {
    payouts: Payout[];
    payoutsPagination?: Pagination;
    isFetchingPayouts: boolean;
    errorFetchingPayouts: boolean;
    openPayout?: Payout;
    isPayingOut: boolean;
    errorPayingOut: boolean;
};

export const PAGE_LIMITS = [
    5,
    10,
    25
];

const initialState: PayoutsState = {
    payouts: [],
    isFetchingPayouts: false,
    errorFetchingPayouts: false,
    isPayingOut: false,
    errorPayingOut: false,
};

export const getPayoutsPageLimit = (state: RootState) => state.payouts.payoutsPagination?.limit || 10;
export const getPayoutsPageNumber = (state: RootState) => state.payouts.payoutsPagination?.page || 1;

export const getPayoutsPagination = (state: RootState) => state.payouts.payoutsPagination;
export const getPayouts = (state: RootState) => state.payouts.payouts;
export const getIsFetchingPayouts = (state: RootState) => state.payouts.isFetchingPayouts;
export const getErrorFetchingPayouts = (state: RootState) => state.payouts.errorFetchingPayouts;

export const getOpenPayout = (state: RootState) => state.payouts.openPayout;
export const getIsPayingOut = (state: RootState) => state.payouts.isPayingOut;
export const getErrorPayingOut = (state: RootState) => state.payouts.errorPayingOut;

export const payoutsSlice = createSlice({
    name: "payouts",
    initialState,
    reducers: {
        setPayouts: (state, action: PayloadAction<Payout[]>) => {
            state.payouts = action.payload;
        },
        setPayoutsPagination: (state, action: PayloadAction<Pagination>) => {
            state.payoutsPagination = action.payload;
        },
        setIsFetchingPayouts: (state, action: PayloadAction<boolean>) => {
            state.isFetchingPayouts = action.payload;
        },
        setErrorFetchingPayouts: (state, action: PayloadAction<boolean>) => {
            state.errorFetchingPayouts = action.payload;
        },
        setOpenPayout: (state, action: PayloadAction<Payout | undefined>) => {
            state.openPayout = action.payload;
        },
        setIsPayingOut: (state, action: PayloadAction<boolean>) => {
            state.isPayingOut = action.payload;
        },
        setErrorPayingOut: (state, action: PayloadAction<boolean>) => {
            state.errorPayingOut = action.payload;
        },
        reset: (state) => {
            state.payouts = [];
            state.payoutsPagination = undefined;
            state.isFetchingPayouts = false;
            state.errorFetchingPayouts = false;
            state.openPayout = undefined;
            state.isPayingOut = false;
            state.errorPayingOut = false;
        }
    },
});

export const fetchPendingPayouts = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(payoutsSlice.actions.setIsFetchingPayouts(true));
        const paginationOptions = {
            limit: getPayoutsPageLimit(getState()),
            page: getPayoutsPageNumber(getState()),
        };
        const {payouts, pagination} = await admin.getPendingPayouts(paginationOptions);
        dispatch(payoutsSlice.actions.setPayouts(payouts));
        dispatch(payoutsSlice.actions.setPayoutsPagination(pagination));
        dispatch(payoutsSlice.actions.setErrorFetchingPayouts(false));
    } catch (e) {
        dispatch(payoutsSlice.actions.setErrorFetchingPayouts(true));
    } finally {
        dispatch(payoutsSlice.actions.setIsFetchingPayouts(false));
    }
};

export const makePayout = (): AppThunk => async (dispatch, getState) => {
    try {
        const payout = getOpenPayout(getState()) as Payout;
        dispatch(payoutsSlice.actions.setIsPayingOut(true));
        await admin.payout(payout.id);
        dispatch(payoutsSlice.actions.setErrorPayingOut(false));
        dispatch(payoutsSlice.actions.setOpenPayout(undefined));
        alert("Payout successful");
    } catch (e: any) {
        e as Error;
        alert(`Something went wrong paying out, ${e.message}`)
        dispatch(payoutsSlice.actions.setErrorPayingOut(true));
    } finally {
        dispatch(payoutsSlice.actions.setIsPayingOut(false));
    }
};

export const handlePayoutPageChange = (pageNumber: number): AppThunk => async (dispatch, getState) => {
    const pagination = getPayoutsPagination(getState()) as Pagination;
    const newPagination = {
        ...pagination,
        page: pageNumber,
    };
    dispatch(payoutsSlice.actions.setPayoutsPagination(newPagination));
    dispatch(fetchPendingPayouts());
};

export const handlePayoutLimitChange = (limit: number): AppThunk => async (dispatch, getState) => {
    const pagination = getPayoutsPagination(getState()) as Pagination;
    const newPagination = {
        ...pagination,
        limit,
        page: 1,
    };
    dispatch(payoutsSlice.actions.setPayoutsPagination(newPagination));
    dispatch(fetchPendingPayouts());
};

export default payoutsSlice.reducer;



