import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardContent,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import useDispatch from "../../hooks/useAppDispatch";
import useSelector from "../../hooks/useAppSelector";
import {
  campaignLinkSlice,
  createCampaignLink,
  getBeakonId,
  getCampaignId,
  matchBeakonToCampaign
} from "../../redux/slices/campaignlink";
import admin from "../../sdk";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const LinkCard = () => {
  const dispatch = useDispatch();
  const beakonId = useSelector(getBeakonId);
  const campaignId = useSelector(getCampaignId);
  const createLink = () => {
    dispatch(createCampaignLink());
  }
  const createMatch = () => {
    dispatch(matchBeakonToCampaign());
  }
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Create Campaign Link
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <TextField
              label={'Campaign Id'}
              value={campaignId}
              onChange={((e) => dispatch(campaignLinkSlice.actions.setCampaignId(e.target.value)))}
            />
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <TextField
              label={'Creator Id'}
              value={beakonId}
              onChange={((e) => dispatch(campaignLinkSlice.actions.setBeakonId(e.target.value)))}
            />
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Button 
              variant="contained"
              onClick={createLink}
            >
              Create Campaign Link
            </Button>
            <span
              style={{ marginLeft: 10 }}
            >
              This will make each other show up in their feed even if there is not overlap in preferences
            </span>
          </Grid>
          <Grid mt={2} item xs={12}>
            <Button
              variant="contained"
              onClick={createMatch}
            >
              Create Campaign Match
            </Button>
            <span
              style={{ marginLeft: 10 }}
            >
              This will match the creator with the campaign.
            </span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const NotifyCard = () => {
  const [isNotifying, setIsNotifying] = React.useState(false);
  const [campaignId, setCampaignId] = React.useState('');
  const notifyCreator = async () => {
    try {
      setIsNotifying(true);
      const response = await admin.notifyBeakonRespondAssignedCampaign(campaignId);
      alert(JSON.stringify(response));
      setCampaignId('');
    } catch (e) {
      console.error(e);
      alert('Error notifying creator');
    } finally {
      setIsNotifying(false);
    }
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Notify Creator
            </Typography>
            <Typography variant="body2" gutterBottom>
              Send an email notification to a creator to respond to their campaign.
            </Typography>
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <TextField
              label={'Campaign Id'}
              value={campaignId}
              onChange={((e) => setCampaignId(e.target.value))}
            />
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Button
              onClick={notifyCreator}
              disabled={isNotifying}
              variant="contained"
            >
              Notify Creator
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};

function CampaignLink() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(campaignLinkSlice.actions.reset());
    }
  }, [])
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Campaign Linking
      </Typography>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <LinkCard />
          <NotifyCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CampaignLink;
