import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useDispatch from "../../hooks/useAppDispatch";
import useSelector from "../../hooks/useAppSelector";
import { fetchPendingPayouts, getOpenPayout, getPayouts, getPayoutsPagination, handlePayoutLimitChange, handlePayoutPageChange, makePayout, payoutsSlice } from "../../redux/slices/payouts";
import { Payout } from "../../sdk/Types";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const jsonToHtml = (json: any): string => {
  if (typeof json !== 'object' || json === null) {
    // Handle non-object values (strings, numbers, booleans, null, etc.)
    return JSON.stringify(json);
  }

  if (Array.isArray(json)) {
    // Handle arrays
    const items = json.map((item) => `<li>${jsonToHtml(item)}</li>`).join('');
    return `<ul>${items}</ul>`;
  }

  // Handle objects
  const keys = Object.keys(json);
  const properties = keys.map((key) => {
    const value = jsonToHtml(json[key]);
    return `<li><strong>${key}:</strong> ${value}</li>`;
  }).join('');
  return `<ul>${properties}</ul>`;
}


const ConfirmPayoutModal = () => {
  const dispatch = useDispatch();
  const openPayout = useSelector(getOpenPayout);
  const handleConfirm = () => {
    dispatch(makePayout());
  };
  const handleClose = () => {
    dispatch(payoutsSlice.actions.setOpenPayout(undefined));
  };
  const htmlString = jsonToHtml(openPayout);

  return (
    <Dialog
      open={!!openPayout}
      onClose={handleClose}
    >
      <DialogTitle>Confirm Task</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to confirm this Payout?
        </DialogContentText>
        <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const PayoutTable = (
  { rows, onPayout }: 
  { rows: Payout[], onPayout: (payout: Payout) => void }
) => {
  const dispatch = useDispatch();
  const pagination = useSelector(getPayoutsPagination);
  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(handlePayoutLimitChange(newLimit));
  };
  const goToPage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(handlePayoutPageChange(newPage + 1));
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              id
            </TableCell>
            <TableCell>
              Account ID
            </TableCell>
            <TableCell>
              User ID
            </TableCell>
            <TableCell>
              User Type
            </TableCell>
            <TableCell>
              Campaign ID
            </TableCell>
            <TableCell>
              Amount
            </TableCell>
            <TableCell>
              Created At
            </TableCell>
            <TableCell>
              Updated At
            </TableCell>
            <TableCell>
              ""
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.map(payout => {
              return (
                <TableRow key={payout.id}>
                  <TableCell>
                    {payout.id}
                  </TableCell>
                  <TableCell>
                    {payout.account_id}
                  </TableCell>
                  <TableCell>
                    {payout.user_id}
                  </TableCell>
                  <TableCell>
                    {payout.user_type}
                  </TableCell>
                  <TableCell>
                    {payout.campaign_id}
                  </TableCell>
                  <TableCell>
                    {payout.amount}
                  </TableCell>
                  <TableCell>
                    {payout.createdAt}
                  </TableCell>
                  <TableCell>
                    {payout.updatedAt}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant={'contained'}
                      onClick={() => onPayout(payout)}
                    >
                      Dispatch
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      {
        pagination ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={pagination.totalDocs}
            rowsPerPage={pagination.limit}
            page={pagination.page - 1}
            onPageChange={goToPage}
            onRowsPerPageChange={handleLimitChange}
          />
        ) : null
      }
    </>
  )
};

const PayoutCard = () => {
  const dispatch = useDispatch();
  const payouts = useSelector(getPayouts);
  const handleConfirmPayout = (payout: Payout) => {
    dispatch(payoutsSlice.actions.setOpenPayout(payout));
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Pending Payouts
        </Typography>
        <Typography variant="body2" gutterBottom>
          Go through the pending payouts and try to dispatch payouts to the beakon's connected accounts.
          Dispatching payouts will move the funds from the App Platform's connected account on Stripe to the beakon's connected account on Stripe.
          This will not send the funds to the beakon's bank account.
        </Typography>
        <PayoutTable
          rows={payouts}
          onPayout={handleConfirmPayout}
        />
        <ConfirmPayoutModal />
      </CardContent>
    </Card>
  );
}

const Payouts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    process.nextTick(() => {
      dispatch(fetchPendingPayouts());
    })
    return () => {
      dispatch(payoutsSlice.actions.reset());
    }
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Payouts
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PayoutCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Payouts;
