
import {
    Button,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow as MuiTableRow,
    Typography,
    TableContainer,
} from "@mui/material";
import styled from "@emotion/styled";
import { tableCellClasses } from '@mui/material/TableCell';
import { FullProposals, MatchWithProposal, SocialAccount, UserModel } from "../../sdk/Types";
import admin from "../../sdk";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const TableRow = styled(MuiTableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CampaignRow = ({
    campaign,
}: {
    campaign: FullProposals | MatchWithProposal;
}) => {
    const isMatch = 'id' in campaign;
    const notify = async () => {
        if (!isMatch) return;
        const confirmed = window.confirm('Are you sure you want to notify the creator of the match? This will resend a push notification if availale and a email.');
        if (confirmed) {
            try {
                const response = await admin.notifyBeakonOfMatch(campaign.id);
                if (response.success) {
                    alert('Notification sent successfully');
                } else {
                    alert(`Failed to send notification, error: ${response.message}`);
                }
            } catch (error) {
                console.error(error);
                alert('Failed to send notification');
            }
        }
    };
    return (
        <TableRow>
            <TableCell>{campaign.proposal.id}</TableCell>
            <TableCell>{campaign.proposal.title}</TableCell>
            <TableCell>{campaign.proposal.description}</TableCell>
            <TableCell>{campaign.proposal.business_id}</TableCell>
            <TableCell>
                {
                    isMatch && <Button
                        variant="contained"
                        color="primary"
                        onClick={notify}
                    >
                        Notify
                    </Button>
                }
            </TableCell>
        </TableRow>
    );
}

const CampaignsTable = ({
    campaigns,
}: {
    campaigns: Array<FullProposals | MatchWithProposal>;
}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Campaign ID</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Business ID</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        campaigns.map(campaign =>
                            <CampaignRow
                                key={campaign.proposal.id}
                                campaign={campaign}
                            />
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default CampaignsTable;