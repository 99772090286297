import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Post } from "../../sdk/Types";
import useDispatch from "../../hooks/useAppDispatch";
import useSelector from "../../hooks/useAppSelector";
import { fetchTasksAwaitingVerification, getTasks, campaigntasksSlice, getOpenTask, getRejectionReason, rejectTask, verifyTask, getTasksPagination, handleTasksLimitChange, handleTasksPageChange, PAGE_LIMITS } from "../../redux/slices/campaigntasks";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const jsonToHtml = (json: any): string => {
  if (typeof json !== 'object' || json === null) {
    // Handle non-object values (strings, numbers, booleans, null, etc.)
    return JSON.stringify(json);
  }

  if (Array.isArray(json)) {
    // Handle arrays
    const items = json.map((item) => `<li>${jsonToHtml(item)}</li>`).join('');
    return `<ul>${items}</ul>`;
  }

  // Handle objects
  const keys = Object.keys(json);
  const properties = keys.map((key) => {
    const value = jsonToHtml(json[key]);
    return `<li><strong>${key}:</strong> ${value}</li>`;
  }).join('');
  return `<ul>${properties}</ul>`;
}

const ManageTasksTable = ({
  rows,
  onVerify,
}: {
  rows: Post[],
  onVerify: (task: Post) => void,
}) => {
  const dispatch = useDispatch();
  const pagination = useSelector(getTasksPagination);
  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(handleTasksLimitChange(newLimit));
  };
  const goToPage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(handleTasksPageChange(newPage + 1));
  };
  return <>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Task Id
          </TableCell>
          <TableCell>
            Campaign ID
          </TableCell>
          <TableCell>
            Business ID
          </TableCell>
          <TableCell>
            Beakon ID
          </TableCell>
          <TableCell>
            Platform
          </TableCell>
          <TableCell>
            Post Type
          </TableCell>
          <TableCell>
            Updated At
          </TableCell>
          <TableCell>
            
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.id}
              </TableCell>
              <TableCell>
                {row.proposal_id}
              </TableCell>
              <TableCell>
                {row.business_id}
              </TableCell>
              <TableCell>
                {row.beakon_id}
              </TableCell>
              <TableCell>
                {row.platform}
              </TableCell>
              <TableCell>
                {row.fulfillmentType}
              </TableCell>
              <TableCell>
                {row.updatedAt}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onVerify(row)}
                >
                  Verify
                </Button>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
    {
      pagination ? (
        <TablePagination
          rowsPerPageOptions={PAGE_LIMITS}
          component="div"
          count={pagination.totalDocs}
          rowsPerPage={pagination.limit}
          page={pagination.page - 1}
          onPageChange={goToPage}
          onRowsPerPageChange={handleLimitChange} 
        /> 
      ) : null
    }
  </>
}


const TaskVerificationModal = () => {
  const dispatch = useDispatch();
  const openTask = useSelector(getOpenTask);
  const rejectionReason = useSelector(getRejectionReason);
  const onCancel = () => {
    dispatch(campaigntasksSlice.actions.setOpenTask());
    dispatch(campaigntasksSlice.actions.setRejectionReason(''));
  };

  const onReject = () => {
    const taskId = openTask?.id;
    const submissionId = openTask?.selection_id;
    if (taskId && submissionId) {
      dispatch(rejectTask(taskId, submissionId));
    }
  }

  const onApprove = () => {
    const taskId = openTask?.id;
    const submissionId = openTask?.selection_id;
    if (taskId && submissionId) {
      dispatch(verifyTask(taskId, submissionId));
    }
  }

  const htmlString = jsonToHtml(openTask);


  return (
    <Dialog
      open={!!openTask}
      onClose={onCancel}
    >
      <DialogTitle>
        Verify Task
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to verify this task?
        </DialogContentText>
        <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} my={4}>
            <TextField
              label="Rejection Reason"
              multiline
              rows={4}
              fullWidth={true}
              value={rejectionReason}
              variant="outlined"
              onChange={(e) => dispatch(campaigntasksSlice.actions.setRejectionReason(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button variant={'contained'} onClick={onCancel}>
              Cancel
            </Button>
            <Button color={"error"} variant={'contained'} disabled={rejectionReason === ""} onClick={onReject}>
              Reject
            </Button>
            <Button color='success' variant={'contained'} onClick={onApprove}>
              Approve
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}


const ManageTasksCard = () => {
  const dispatch = useDispatch();
  const tasks = useSelector(getTasks);
  const onVerifyClick = (task: Post) => {
    dispatch(campaigntasksSlice.actions.setOpenTask(task));
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Tasks Pending Verification
        </Typography>
        <Typography variant="body2" gutterBottom>
          Go through the tasks pending verification and either approve or reject them.
        </Typography>
        <ManageTasksTable 
          rows={tasks} 
          onVerify={onVerifyClick}
        />
        <TaskVerificationModal />
      </CardContent>
    </Card>
  );
}

const CampaignTasks = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchTasksAwaitingVerification());
    }, 1000);
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Campaign Tasks
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Tasks</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ManageTasksCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CampaignTasks;
