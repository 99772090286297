import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store";
import { BusinessModel, Pagination, UserModel } from "../../sdk/Types";
import admin from "../../sdk";

export type userFilters = 'unverified' | 'new';

export const PAGE_LIMITS = [
    5,
    10,
    25
];

export interface UsersState {
    beakons: UserModel[];
    beakonsPagination?: Pagination;
    isFetchingBeakons: boolean;
    errorFetchingBeakons: boolean;
    businesses: BusinessModel[];
    businessesPagination?: Pagination;
    isFetchingBusinesses: boolean;
    errorFetchingBusinesses: boolean;
    isVerifyingUser: boolean;
    errorVerifyingUser: boolean;
}
  
const initialState: UsersState = {
    beakons: [],
    isFetchingBeakons: false,
    errorFetchingBeakons: false,
    businesses: [],
    isFetchingBusinesses: false,
    errorFetchingBusinesses: false,
    isVerifyingUser: false,
    errorVerifyingUser: false,
};

export const getBeakonsPageLimit = (state: RootState) => state.users.beakonsPagination?.limit || 10;
export const getBeakonsPageNumber = (state: RootState) => state.users.beakonsPagination?.page || 1;

export const getBusinessesPageLimit = (state: RootState) => state.users.businessesPagination?.limit || 10;
export const getBusinessesPageNumber = (state: RootState) => state.users.businessesPagination?.page || 1;

export const getBeakonsPagination = (state: RootState) => state.users.beakonsPagination;
export const getBusinessesPagination = (state: RootState) => state.users.businessesPagination;

export const getBeakons = (state: RootState) => state.users.beakons;
export const getBusinesses = (state: RootState) => state.users.businesses;

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setBeakons: (state, action: PayloadAction<UserModel[]>) => {
            state.beakons = action.payload;
        },
        setBeakonsPagination: (state, action: PayloadAction<Pagination>) => {
            state.beakonsPagination = action.payload;
        },
        setIsFetchingBeakons: (state, action: PayloadAction<boolean>) => {
            state.isFetchingBeakons = action.payload;
        },
        setErrorFetchingBeakons: (state, action: PayloadAction<boolean>) => {
            state.errorFetchingBeakons = action.payload;
        },
        setBusinesses: (state, action: PayloadAction<BusinessModel[]>) => {
            state.businesses = action.payload;
        },
        setBusinessesPagination: (state, action: PayloadAction<Pagination>) => {
            state.businessesPagination = action.payload;
        },
        setIsFetchingBusinesses: (state, action: PayloadAction<boolean>) => {
            state.isFetchingBusinesses = action.payload;
        },
        setErrorFetchingBusinesses: (state, action: PayloadAction<boolean>) => {
            state.errorFetchingBusinesses = action.payload;
        },
        setIsVerifyingUser: (state, action: PayloadAction<boolean>) => {
            state.isVerifyingUser = action.payload;
        },
        setErrorVerifyingUser: (state, action: PayloadAction<boolean>) => {
            state.errorVerifyingUser = action.payload;
        },
        reset: (state) => {
            state.beakons = [];
            state.beakonsPagination = undefined;
            state.isFetchingBeakons = false;
            state.errorFetchingBeakons = false;
            state.businesses = [];
            state.businessesPagination = undefined;
            state.isFetchingBusinesses = false;
            state.errorFetchingBusinesses = false;
            state.isVerifyingUser = false;
            state.errorVerifyingUser = false;
        },
    },
});

export const fetchBeakons = (filter: userFilters): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(usersSlice.actions.setIsFetchingBeakons(true));
        const state = getState();

        const paginatonOptions = {
            limit: getBeakonsPageLimit(state),
            page: getBeakonsPageNumber(state),
        }
        const { users, pagination } = await admin.getBeakons(filter, paginatonOptions);
        dispatch(usersSlice.actions.setBeakons(users));
        dispatch(usersSlice.actions.setBeakonsPagination(pagination));
        dispatch(usersSlice.actions.setErrorFetchingBeakons(false));
    } catch (e) {
        dispatch(usersSlice.actions.setErrorFetchingBeakons(true));
    } finally {
        dispatch(usersSlice.actions.setIsFetchingBeakons(false));
    }
}

export const fetchBusinesses = (filter: userFilters): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(usersSlice.actions.setIsFetchingBusinesses(true));
        const state = getState();
        const paginationOptions = {
            limit: getBusinessesPageLimit(state),
            page: getBusinessesPageNumber(state),
        };
        const { businesses, pagination } = await admin.getBusinesses(filter, paginationOptions);
        dispatch(usersSlice.actions.setBusinesses(businesses));
        dispatch(usersSlice.actions.setBusinessesPagination(pagination));
        dispatch(usersSlice.actions.setErrorFetchingBusinesses(false));
    } catch (e) {
        dispatch(usersSlice.actions.setErrorFetchingBusinesses(true));
    } finally {
        dispatch(usersSlice.actions.setIsFetchingBusinesses(false));
    }
};

export const handleBeakonsPageChange = (newPage: number): AppThunk => async (dispatch, getState) => {
    const beakonPagination = getBeakonsPagination(getState()) as Pagination;
    const newBeakonPagination = {
        ...beakonPagination,
        page: newPage,
    };
    dispatch(usersSlice.actions.setBeakonsPagination(newBeakonPagination));
    dispatch(fetchBeakons('unverified'));
};

export const handleBeakonsLimitChange = (newLimit: number): AppThunk => async (dispatch, getState) => {
    const beakonPagination = getBeakonsPagination(getState()) as Pagination;
    const newBeakonPagination = {
        ...beakonPagination,
        limit: newLimit,
        page: 1, // always go back to first page
    };
    dispatch(usersSlice.actions.setBeakonsPagination(newBeakonPagination));
    dispatch(fetchBeakons('unverified'));
};

export const handleBusinessesPageChange = (newPage: number): AppThunk => async (dispatch, getState) => {
    const businessesPagination = getBusinessesPagination(getState()) as Pagination;
    const newBusinessesPagination = {
        ...businessesPagination,
        page: newPage,
    };
    dispatch(usersSlice.actions.setBusinessesPagination(newBusinessesPagination));
    dispatch(fetchBusinesses('unverified'));
};

export const handleBusinessesLimitChange = (newLimit: number): AppThunk => async (dispatch, getState) => {
    const businessesPagination = getBusinessesPagination(getState()) as Pagination;
    const newBusinessesPagination = {
        ...businessesPagination,
        limit: newLimit,
        page: 1, // always go back to first page
    };
    dispatch(usersSlice.actions.setBusinessesPagination(newBusinessesPagination));
    dispatch(fetchBusinesses('unverified'));
};

export const verifyUser = (userId: string, userType: 'beakon' | 'business'): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(usersSlice.actions.setIsVerifyingUser(true));
        const verifyPayload = {
            id: userId,
        }
        let verifyResponse = null;
        if (userType === 'beakon') {
            verifyResponse = await admin.verifyBeakon(verifyPayload);
        } else {
            verifyResponse = await admin.verifyBusiness(verifyPayload);
        }
        console.log({ verifyResponse })
        dispatch(usersSlice.actions.setErrorVerifyingUser(false));
    } catch (e) {
        dispatch(usersSlice.actions.setErrorVerifyingUser(true));
    } finally {
        dispatch(usersSlice.actions.setIsVerifyingUser(false));
    }
}

export default usersSlice.reducer;