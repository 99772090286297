import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store";
import admin from "../../sdk";


export interface CampaignLinkState {
    beakonId: string;
    campaignId: string;
    isCreatingCampaignLink: boolean;
    errorCreatingCampaignLink: boolean;
    isMatchingBeakonToCampaign: boolean;
    errorMatchingBeakonToCampaign: boolean;
}

const initialState: CampaignLinkState = {
    beakonId: "",
    campaignId: "",
    isCreatingCampaignLink: false,
    errorCreatingCampaignLink: false,
    isMatchingBeakonToCampaign: false,
    errorMatchingBeakonToCampaign: false,
};

export const getBeakonId = (state: RootState) => state.campaignlink.beakonId;
export const getCampaignId = (state: RootState) => state.campaignlink.campaignId;   

export const campaignLinkSlice = createSlice({
    name: "campaignlink",
    initialState,
    reducers: {
        setBeakonId: (state, action: PayloadAction<string>) => {
            state.beakonId = action.payload;
        },
        setCampaignId: (state, action: PayloadAction<string>) => {
            state.campaignId = action.payload;
        },
        setIsCreatingCampaignLink: (state, action: PayloadAction<boolean>) => {
            state.isCreatingCampaignLink = action.payload;
        },
        setErrorCreatingCampaignLink: (state, action: PayloadAction<boolean>) => {
            state.errorCreatingCampaignLink = action.payload;
        },
        setIsMatchingBeakonToCampaign: (state, action: PayloadAction<boolean>) => {
            state.isMatchingBeakonToCampaign = action.payload;
        },
        setErrorMatchingBeakonToCampaign: (state, action: PayloadAction<boolean>) => {
            state.errorMatchingBeakonToCampaign = action.payload;
        },
        reset: (state) => {
            state.beakonId = "";
            state.campaignId = "";
            state.isCreatingCampaignLink = false;
            state.errorCreatingCampaignLink = false;
            state.isMatchingBeakonToCampaign = false;
            state.errorMatchingBeakonToCampaign = false;
        }
    },
});

export const createCampaignLink = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(campaignLinkSlice.actions.setIsCreatingCampaignLink(true));
        const { beakonId, campaignId } = getState().campaignlink;
        await admin.connectBeakonToCampaign(campaignId, beakonId);
        dispatch(campaignLinkSlice.actions.setErrorCreatingCampaignLink(false));
        alert("Campaign link created successfully");
        dispatch(campaignLinkSlice.actions.setBeakonId(""));
        dispatch(campaignLinkSlice.actions.setCampaignId(""));
    } catch (e) {
        dispatch(campaignLinkSlice.actions.setErrorCreatingCampaignLink(true));
    } finally {
        dispatch(campaignLinkSlice.actions.setIsCreatingCampaignLink(false));
    }
};

export const matchBeakonToCampaign = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(campaignLinkSlice.actions.setIsMatchingBeakonToCampaign(true));
        const { beakonId, campaignId } = getState().campaignlink;
        await admin.matchBeakonToCampaign(campaignId, beakonId);
        dispatch(campaignLinkSlice.actions.setErrorMatchingBeakonToCampaign(false));
        alert("Beakon matched to campaign successfully");
        dispatch(campaignLinkSlice.actions.setBeakonId(""));
        dispatch(campaignLinkSlice.actions.setCampaignId(""));
    } catch (e) {
        dispatch(campaignLinkSlice.actions.setErrorMatchingBeakonToCampaign(true));
    } finally {
        dispatch(campaignLinkSlice.actions.setIsMatchingBeakonToCampaign(false));
    }
};

export default campaignLinkSlice.reducer;