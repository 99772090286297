
import {
    Button,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow as MuiTableRow,
    Typography,
    TableContainer,
} from "@mui/material";
import styled from "@emotion/styled";
import { tableCellClasses } from '@mui/material/TableCell';
import { SocialAccount, UserModel } from "../../sdk/Types";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const TableRow = styled(MuiTableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const SocialAccountsTable = ({
    beakon,
    onVerifySocialAccount,
}: {
    beakon: UserModel,
    onVerifySocialAccount?: (socialAccount: SocialAccount) => any
}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Platform</TableCell>
                        <TableCell>Handle</TableCell>
                        <TableCell>Verified</TableCell>
                        <TableCell>Additional Data</TableCell>
                        <TableCell>ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        beakon.socialAccounts && beakon.socialAccounts.map((socialAccount: SocialAccount) => {
                            return (
                                <TableRow key={socialAccount.id}>
                                    <TableCell>
                                        {socialAccount.platform}
                                    </TableCell>
                                    <TableCell>
                                        {socialAccount.handle}
                                    </TableCell>
                                    <TableCell>
                                        {socialAccount.verified ? 'Yes' : 'No'}
                                        {
                                            !socialAccount.verified &&
                                            <Button
                                                onClick={
                                                    () => {
                                                        onVerifySocialAccount && onVerifySocialAccount(socialAccount)
                                                    }
                                                }
                                            >
                                                Verify
                                            </Button>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            socialAccount.apifyData && <>
                                                <Typography variant="body2" gutterBottom>
                                                    <b>username: </b>{socialAccount.apifyData.username}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <b>Bio: </b>{socialAccount.apifyData.biography}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    <b>Followers: </b>{socialAccount.apifyData.followersCount}
                                                </Typography>
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {socialAccount.id}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default SocialAccountsTable;