import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TablePagination,
  TableRow as MuiTableRow,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';

import { spacing } from "@mui/system";
import useDispatch from "../../hooks/useAppDispatch";
import useSelector from "../../hooks/useAppSelector";
import { fetchBeakons, fetchBusinesses, getBeakons, getBeakonsPagination, getBusinesses, getBusinessesPagination, handleBeakonsLimitChange, handleBeakonsPageChange, handleBusinessesLimitChange, handleBusinessesPageChange, usersSlice, verifyUser } from "../../redux/slices/users";
import admin from "../../sdk";
import { BusinessModel, MatchWithProposal, SocialAccount } from "../../sdk/Types";
import SocialAccountsTable from "../components/SocialAccountsTable";
import CampaignsTable from "../components/CampaignsTable";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


interface UnverifiedUsersType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  instaHandle: string;
  createdAt: Date;
  updatedAt: Date;
};

interface UnverifiedBusinessType {
  id: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
};

const VerifySocialAccountCard = () => {
  const [instagramHandle, setInstagramHandle] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');
  const buttonDisabled = instagramHandle.length === 0 || verificationCode.length === 0;
  const verify = async () => {
    const {
      success,
      message,
      possibleSocialAccounts,
    } = await admin.verifyInstagramSocialAccount(instagramHandle, verificationCode);
    setInstagramHandle('');
    setVerificationCode('');
    if (success) {
      alert(message);
      return;
    }
    alert(`Failed to verify social account, maybe one of the following is the correct handle: ${possibleSocialAccounts.join(', ')}`);
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Verify Instagram Social Account
        </Typography>
        <TextField
          label="Instagram Handle"
          variant="outlined"
          placeholder="whatever"
          fullWidth
          my={2}
          onChange={(e) => setInstagramHandle(e.target.value)}
        />
        <TextField
          label="Verification Code"
          variant="outlined"
          placeholder="XXXXXXXXXX"
          fullWidth
          my={2}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <Button
          disabled={buttonDisabled}
          onClick={verify}
          variant="contained"
          color="primary"
        >
          Verify
        </Button>
      </CardContent>
    </Card>
  );

}

const EnrichSocialAccountCard = () => {
  const [socialAccountId, setSocialAccountId] = React.useState('');
  const [isEnriching, setIsEnriching] = React.useState(false);
  const enrich = async () => {
    try {
      setIsEnriching(true);
      const socialAccount = await admin.enrichSocialAccount(socialAccountId);
      console.log({
        socialAccount
      });
      alert('Social Account Enriched');
    } catch (e) {
      alert('Failed to enrich social account')
      console.error('Failed to enrich social account', e);
    } finally {
      setIsEnriching(false);

    }
  };
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Enrich Social Account
        </Typography>
        <TextField
          label="Social Account Id"
          variant="outlined"
          placeholder="whatever"
          fullWidth
          my={2}
          value={socialAccountId}
          onChange={(e) => setSocialAccountId(e.target.value)}
        />
        <Button
          disabled={isEnriching}
          onClick={enrich}
          variant="contained"
          color="primary"
        >
          Enrich
        </Button>
      </CardContent>
    </Card>
  );
}

const NewUsersCard = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          New Users
        </Typography>
        <Typography variant="body2" gutterBottom>
          Number of New SignUps
        </Typography>
      </CardContent>
    </Card>
  );
}

const BusinessesTable = ({ rows, verifyBusiness }: {
  rows: UnverifiedBusinessType[];
  verifyBusiness: (business: UnverifiedBusinessType) => void;
}) => {
  const dispatch = useDispatch();
  const businessesPagination = useSelector(getBusinessesPagination);
  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(handleBusinessesLimitChange(newLimit));
  };
  const goToPage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(handleBusinessesPageChange(newPage + 1));
  };
  return (
    <><Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell align="right">Signup Date</TableCell>
          <TableCell align="right">
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell>
                  {row.email}
                </TableCell>
                <TableCell align="right">
                  {row.createdAt.toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => { verifyBusiness(row) }}
                    variant="contained"
                    color="primary">
                    Verify Business
                  </Button>
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
      {
        businessesPagination ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={businessesPagination.totalDocs}
            rowsPerPage={businessesPagination.limit}
            page={businessesPagination.page - 1}
            onPageChange={goToPage}
            onRowsPerPageChange={handleLimitChange}
          />
        ) : null
      }
    </>
  )
};


const BeakonsTable = ({ rows, verifyUser }: {
  rows: UnverifiedUsersType[];
  verifyUser: (user: UnverifiedUsersType) => void;
}) => {
  const dispatch = useDispatch();
  const beakonsPagination = useSelector(getBeakonsPagination);
  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(handleBeakonsLimitChange(newLimit));
  };
  const goToPage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(handleBeakonsPageChange(newPage + 1));
  };
  return (
    <><Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell align="right">First Name</TableCell>
          <TableCell align="right">Last Name</TableCell>
          <TableCell align="right">Signup Date</TableCell>
          <TableCell align="right">Updated At Date</TableCell>
          <TableCell align="right">IG Handle</TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {row.email}
            </TableCell>
            <TableCell align="right">{row.firstName}</TableCell>
            <TableCell align="right">{row.lastName}</TableCell>
            <TableCell align="right">{row.createdAt.toLocaleString()}</TableCell>
            <TableCell align="right">{row.updatedAt.toLocaleString()}</TableCell>
            <TableCell align="right">{row.instaHandle}</TableCell>
            <TableCell align="right">
              <Button
                onClick={() => { verifyUser(row) }}
                variant="contained"
                color="primary">
                Verify  Beakon
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      {
        beakonsPagination ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={beakonsPagination.totalDocs}
            rowsPerPage={beakonsPagination.limit}
            page={beakonsPagination.page - 1}
            onPageChange={goToPage}
            onRowsPerPageChange={handleLimitChange}
          />
        ) : null
      }
    </>
  )

}

const BeakonsPendingVerification = () => {
  const dispatch = useDispatch();
  const beakons = useSelector(getBeakons) as UnverifiedUsersType[];
  const verify = async (user: UnverifiedUsersType) => {
    const confirmed = window.confirm(`Are you sure you want to verify ${user.firstName} ${user.lastName} - ${user.email}?`);
    if (confirmed) {
      // dispatch the action
      await dispatch(verifyUser(user.id, 'beakon'));
      window.location.reload();
    }
  }
  useEffect(() => {
    process.nextTick(() => {
      dispatch(fetchBeakons('unverified'));
    });

  }, []);
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Beakons Pending Verification
        </Typography>
        <BeakonsTable rows={beakons} verifyUser={verify} />
      </CardContent>
    </Card>
  );
}

const BusinessesPendingVerification = () => {
  const dispatch = useDispatch();
  const businesses = useSelector(getBusinesses) as UnverifiedBusinessType[];
  const verifyBusiness = async (business: UnverifiedBusinessType) => {
    const confirmed = window.confirm(`Are you sure you want to verify ${business.email}?`);
    if (confirmed) {
      // dispatch the action
      await dispatch(verifyUser(business.id, 'business'));
      window.location.reload();
    }
  }
  useEffect(() => {
    process.nextTick(() => {
      dispatch(fetchBusinesses('unverified'));
    });

  }, []);
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Businesses Pending Verification
        </Typography>
        <BusinessesTable rows={businesses} verifyBusiness={verifyBusiness} />
      </CardContent>
    </Card>
  );
}

const UserAuth = () => {
  const [beakonId, setBeakonId] = React.useState('');
  const getAuthToken = async () => {
    const repsonse = await admin.getBeakonAuth(beakonId);
    alert(JSON.stringify(repsonse, null, 2));
  }
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          User Auth
        </Typography>
        <TextField
          label="Beakon Id"
          variant="outlined"
          fullWidth
          onChange={(e) => setBeakonId(e.target.value)}
          my={2}
        />
        <Button onClick={getAuthToken} variant="contained" color="primary">
          Get Auth
        </Button>
      </CardContent>
    </Card>
  );
}

const BeakonInformation = ({
  beakon,
  onVerifySocialAccount
}: {
  beakon: any,
  onVerifySocialAccount?: (socialAccount: SocialAccount) => void
}) => {
  const [matches, setMatches] = React.useState<MatchWithProposal[]>([]);
  const fetchMatches = async () => {
    const response = await admin.getBeakonMatches(beakon.id, {
      limit: 20,
      page: 1
    });
    setMatches(response.matches);
  }

  useEffect(() => {
    fetchMatches();
  }, []);

  return <Grid
    container
  >
    <Grid item style={{
      marginTop: '1rem'

    }} xs={12}>
      <Typography variant="h6" gutterBottom>
        Creator Information
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>
        <b>ID: </b>{beakon.id}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>
        <b>Name: </b>{beakon.firstName} {beakon.lastName}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>
        <b>Email: </b>{beakon.email}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>
        <b>Date Signed Up: </b>{(new Date(beakon.createdAt)).toString()}
      </Typography>
    </Grid>
    <Grid item style={{
      marginTop: '1rem'
    }} xs={12}>
      <Typography variant="h6" gutterBottom>
        Social Accounts
      </Typography>
    </Grid>
    <SocialAccountsTable 
      beakon={beakon}
      onVerifySocialAccount={onVerifySocialAccount}
    />

    <Grid item style={{
      marginTop: '1rem'
    }} xs={12}>
      <Typography variant="h6" gutterBottom>
        Matches
      </Typography>
    </Grid>
    <CampaignsTable campaigns={matches} />
  </Grid>
}

const BusinessInformation = ({ business }: { business: BusinessModel }) => {
  return <Grid
    container
  >
    <Grid item style={{
      marginTop: '1rem'
    }} xs={12}>
      <Typography variant="h6" gutterBottom>
        Business Information
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>
        {business.email}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>
        {business.name}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" gutterBottom>
        Created At: {(new Date(business.createdAt)).toDateString()}
      </Typography>
    </Grid>
    <Grid style={{
      marginTop: '1rem'
    }} item xs={12}>
      <Typography variant="h6" gutterBottom>
        God Mode Link
      </Typography>
    </Grid>
    <Grid
      item
      xs={12}
    >
      {`https://app.beakongage.com/impersonate/${business.id}`}
    </Grid>
  </Grid>

}


const FindBusinessCard = () => {
  const [businessEmail, setBusinessEmail] = React.useState('');
  const [business, setBusiness] = React.useState<any>(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const findBusiness = async () => {
    try {
      setIsFetching(true);
      const response = await admin.getBusinessByEmail(businessEmail);
      if (!response.success) {
        throw new Error('Failed to find business');
      }
      setBusiness(response.business);
    } catch (e) {
      alert('Failed to find business');
      console.error('Failed to find business', e);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          Find Business
        </Typography>
        <TextField
          label="Business Email"
          variant="outlined"
          fullWidth
          onChange={(e) => setBusinessEmail(e.target.value)}
          my={2}
        />
        <Button
          disabled={isFetching}
          onClick={findBusiness}
          variant="contained"
          color="primary"
        >
          Find Business
        </Button>
        {
          business && <BusinessInformation business={business} />
        }
      </CardContent>
    </Card>
  );

}

const FindBeakonCard = () => {
  const [beakonEmail, setBeakonEmail] = React.useState('');
  const [beakon, setBeakon] = React.useState<any>(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const findBeakon = async () => {
    try {
      setIsFetching(true);
      const response = await admin.getBeakonByEmail(beakonEmail);
      setBeakon(response);
    } catch (e) {
      alert('Failed to find creator');
      console.error('Failed to find creator', e);
    } finally {
      setIsFetching(false);
    }
  }

  const verifySocialAccount = async (socialAccount: SocialAccount) => {
    try {
      const response = await admin.verifySocialAccountBasic(socialAccount.id);
      if (response.success) {
        alert('Social Account Verified');
        await findBeakon();
      } else {
        alert('Failed to verify social account');
      }
    } catch (e) {
      alert('Failed to verify social account');
      console.error('Failed to verify social account', e);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          Find Creator
        </Typography>
        <TextField
          label="Creator Email"
          variant="outlined"
          fullWidth
          onChange={(e) => setBeakonEmail(e.target.value)}
          my={2}
        />
        <Button
          disabled={isFetching}
          onClick={findBeakon}
          variant="contained"
          color="primary"
        >
          Find Creator
        </Button>
        {
          beakon && <BeakonInformation 
              onVerifySocialAccount={verifySocialAccount} 
              beakon={beakon}
              />
        }
      </CardContent>
    </Card>
  );
}

const BusinessAuth = () => {
  const [businessId, setBusinessId] = React.useState('');
  const getAuthToken = async () => {
    const repsonse = await admin.getBusinessAuth(businessId);
    console.log(repsonse);
    alert(JSON.stringify(repsonse, null, 2));
  }
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          Business Auth
        </Typography>
        <TextField
          label="Business Id"
          variant="outlined"
          fullWidth
          onChange={(e) => setBusinessId(e.target.value)}
          my={2}
        />
        <Button onClick={getAuthToken} variant="contained" color="primary">
          Get Auth
        </Button>
      </CardContent>
    </Card>
  );
}

const Users = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(usersSlice.actions.reset());
    }
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Users
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          User
        </Link>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FindBusinessCard />
        </Grid>
        <Grid item xs={12}>
          <FindBeakonCard />
        </Grid>
        <Grid item xs={12}>
          <VerifySocialAccountCard />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <EnrichSocialAccountCard />
        </Grid>
        <Grid item xs={12}>
          <UserAuth />
        </Grid>
        <Grid item xs={12}>
          <BusinessAuth />
        </Grid>
        <Grid item xs={12}>
          <NewUsersCard />
        </Grid>
        <Grid item xs={12}>
          <BeakonsPendingVerification />
        </Grid>
        <Grid item xs={12}>
          <BusinessesPendingVerification />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Users;
