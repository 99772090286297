import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../store";
import admin from "../../sdk";
import { Pagination, Post } from "../../sdk/Types";
export const PAGE_LIMITS = [
    5,
    10,
    25
];
export interface CampaignTasksState {
    tasks: Post[];
    tasksPagination?: Pagination;
    isFetchingTasks: boolean;
    errorFetchingTasks: boolean;
    openTask?: Post;
    isApprovingOrRejectingTask: boolean;
    errorApprovingOrRejectingTask: boolean;
    rejectionReason: string;
};

const initialState: CampaignTasksState = {
    tasks: [],
    isFetchingTasks: false,
    errorFetchingTasks: false,
    isApprovingOrRejectingTask: false,
    errorApprovingOrRejectingTask: false,
    rejectionReason: "",
};

export const getTasks = (state: RootState) => state.campaigntasks.tasks;
export const getTasksPagination = (state: RootState) => state.campaigntasks.tasksPagination;
export const getTasksPageLimit = (state: RootState) => state.campaigntasks.tasksPagination?.limit || 10;
export const getTasksPageNumber = (state: RootState) => state.campaigntasks.tasksPagination?.page || 1;
export const getIsFetchingTasks = (state: RootState) => state.campaigntasks.isFetchingTasks;
export const getErrorFetchingTasks = (state: RootState) => state.campaigntasks.errorFetchingTasks;
export const getOpenTask = (state: RootState) => state.campaigntasks.openTask;
export const getIsApprovingOrRejectingTask = (state: RootState) => state.campaigntasks.isApprovingOrRejectingTask;
export const getErrorApprovingOrRejectingTask = (state: RootState) => state.campaigntasks.errorApprovingOrRejectingTask;
export const getRejectionReason = (state: RootState) => state.campaigntasks.rejectionReason;

export const campaigntasksSlice = createSlice({
    name: "campaigntasks",
    initialState,
    reducers: {
        setTasks: (state, action: PayloadAction<Post[]>) => {
            state.tasks = action.payload;
        },
        setTasksPagination: (state, action: PayloadAction<Pagination>) => {
            state.tasksPagination = action.payload;
        },
        setIsFetchingTasks: (state, action: PayloadAction<boolean>) => {
            state.isFetchingTasks = action.payload;
        },
        setErrorFetchingTasks: (state, action: PayloadAction<boolean>) => {
            state.errorFetchingTasks = action.payload;
        },
        setOpenTask: (state, action: PayloadAction<Post | undefined>) => {
            state.openTask = action.payload;
        },
        setIsApprovingOrRejectingTask: (state, action: PayloadAction<boolean>) => {
            state.isApprovingOrRejectingTask = action.payload;
        },
        setErrorApprovingOrRejectingTask: (state, action: PayloadAction<boolean>) => {
            state.errorApprovingOrRejectingTask = action.payload;
        },
        setRejectionReason: (state, action: PayloadAction<string>) => {
            state.rejectionReason = action.payload;
        },
        reset: (state) => {
            state.tasks = [];
            state.tasksPagination = undefined;
            state.isFetchingTasks = false;
            state.errorFetchingTasks = false;
            state.openTask = undefined;
            state.isApprovingOrRejectingTask = false;
            state.errorApprovingOrRejectingTask = false;
            state.rejectionReason = "";
        }
    },
});


export const fetchTasksAwaitingVerification = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(campaigntasksSlice.actions.setIsFetchingTasks(true));
        const state = getState();
        const paginationOptions = {
            limit: getTasksPageLimit(state),
            page: getTasksPageNumber(state),
        };
        const { posts, pagination } = await admin.getTasksAwaitingVerification(paginationOptions);
        dispatch(campaigntasksSlice.actions.setTasks(posts));
        dispatch(campaigntasksSlice.actions.setTasksPagination(pagination));
        dispatch(campaigntasksSlice.actions.setErrorFetchingTasks(false));
    } catch (e) {
        dispatch(campaigntasksSlice.actions.setErrorFetchingTasks(true));
    } finally {
        dispatch(campaigntasksSlice.actions.setIsFetchingTasks(false));
    }
};

export const verifyTask = (taskId: string, submissionId: string): AppThunk => async (dispatch) => {
    try {
        dispatch(campaigntasksSlice.actions.setIsApprovingOrRejectingTask(true));
        await admin.verifyTask(taskId, submissionId);
        alert('Task verified successfully');
        dispatch(campaigntasksSlice.actions.setErrorApprovingOrRejectingTask(false));
        dispatch(campaigntasksSlice.actions.setOpenTask(undefined));
    } catch (e: any) {
        e as Error;
        alert(`Erroring verifying task: ${e.message}`);
        dispatch(campaigntasksSlice.actions.setErrorApprovingOrRejectingTask(true));
    } finally {
        dispatch(campaigntasksSlice.actions.setIsApprovingOrRejectingTask(false));
    }
};

export const rejectTask = (taskId: string, submissionId: string): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(campaigntasksSlice.actions.setIsApprovingOrRejectingTask(true));
        const reason = getRejectionReason(getState());
        await admin.rejectTaskVerification(taskId, submissionId, reason);
        dispatch(campaigntasksSlice.actions.setErrorApprovingOrRejectingTask(false));
        dispatch(campaigntasksSlice.actions.setOpenTask(undefined));
        dispatch(campaigntasksSlice.actions.setRejectionReason(""));
    } catch (e: any) {
        e as Error;
        alert(`Erroring rejecting task: ${e.message}`);
        dispatch(campaigntasksSlice.actions.setErrorApprovingOrRejectingTask(true));
    } finally {
        dispatch(campaigntasksSlice.actions.setIsApprovingOrRejectingTask(false));
    }
};


export const handleTasksPageChange = (newPage: number): AppThunk => async (dispatch, getState) => {
    const pagination = getTasksPagination(getState()) as Pagination;
    const newPagination = {
        ...pagination,
        page: newPage,
    };
    dispatch(campaigntasksSlice.actions.setTasksPagination(newPagination));
    dispatch(fetchTasksAwaitingVerification());
};

export const handleTasksLimitChange = (newLimit: number): AppThunk => async (dispatch, getState) => {
    const pagination = getTasksPagination(getState()) as Pagination;
    const newPagination = {
        ...pagination,
        limit: newLimit,
        page: 1, // always go back to first page
    };
    dispatch(campaigntasksSlice.actions.setTasksPagination(newPagination));
    dispatch(fetchTasksAwaitingVerification());
};

export default campaigntasksSlice.reducer;