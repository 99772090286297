export interface Participant {
  userId: string;
  userType: string;
}
export interface Chat {
  id?: string;
  _id?: string;
  createdAt?: Date | number;
  updatedAt?: Date | number;
  participants: Array<Participant>;
  participantsInfo?: Array<any>;
  title: string;
  createdBy: any | Participant;
}

export interface JoinChatPayload {
  chatId: string;
}

export interface LeaveChatPayload extends JoinChatPayload { }

export interface BusinessProfile {
  name?: string;
  description?: string;
  email: string;
  password: string;
}

export interface BeakonProfile {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface MessagePayload {
  text: string;
}

export interface Sender {
  userId: string;
  userType: string;
}
export interface Receipt {
  _id: string;
  userId: string;
  userType: string;
  createdAt: string;
}
export interface ReadReceipt {
  userId: string;
  userType: string;
  createdAt: string;
}

export interface Message {
  id: string;
  _id?: string;
  chatId: string;
  createdAt: Date | number;
  updatedAt: Date | number;
  payload: any | MessagePayload;
  sender: any | Sender;
  createdBy: any | Sender;
  receipts: Array<Receipt>;
  readReceipts: Array<ReadReceipt>;
  metadata?: any;
}

export interface PhoneObject {
  countryCode: string;
  number: string;
  fullNumber: string;
}
export interface BusinessAddress {
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  postal?: string;
  stateOrProvince?: string;
}

export interface BusinessRep {
  firstName: string;
  lastName: string;
}

export interface BusinessProfileMedia {
  thumbnailUrl?: string;
  fullSizeUrl?: string;
}

export interface BusinessModel {
  id?: string;
  _id?: string;
  name: string;
  profileMedia?: BusinessProfileMedia;
  passwordHash?: string;
  createdAt: Date | number;
  updatedAt: Date | number;
  email: string;
  description?: string;
  address?: BusinessAddress;
  phone?: PhoneObject;
  verified?: boolean;
  internalVerified?: boolean;
  emailVerified?: boolean;
  businessRep?: BusinessRep;
}

export interface UserModel {
  createdAt?: Date;
  updatedAt?: Date;
  firstName: string;
  lastName: string;
  email: string;
  passwordHash?: string;
  origin?: string;
  birthday?: Date;
  autoHandle?: string;
  handle?: string;
  instaHandle?: string;
  verified?: boolean;
  internalVerified?: boolean;
  emailVerified?: boolean;
  address?: any;
  preference?: any;
  bio?: string;
  profilePicture?: string | String;
  socialAccounts?: Array<SocialAccount>;
}

export interface FileUploadResponse {
  success: Boolean;
  file: {
    id: string;
  };
}

export interface TagLabel {
  locale: string;
  value: string;
}

export interface Tag {
  id?: string;
  _id?: string;
  parentId?: string;
  extras: any;
  labels: TagLabel[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface DeepTag extends Tag {
  children?: Tag[];
}

export interface FullDepthTag extends Tag {
  children?: FullDepthTag[];
}

export const beakonUserType = "beakon";

export const businessUserType = "business";

export type MEDIA_TYPE_PRIMARY = "primary";
export interface MediaObject {
  type: MEDIA_TYPE_PRIMARY;
  url: string;
  title: string;
  width: number;
  height: number;
}

export interface Proposal {
  _id?: string;
  id?: string;
  title: string;
  description: string;
  business_id: string;
  business?: any;
  beakon_id?: string;
  match_id?: string;
  media: [MediaObject];
  state: string;
  createdAt: Date;
  updatedAt: Date;
  job: boolean;
  tagIds: string[];
  tags?: Array<Tag>;
}

// To Do Add Stricter Types to the FullProposals SDK
export interface FullProposals {
  proposal: Proposal;
  posts: any;
}

export interface UserProfileSummary {
  firstName: string;
  lastNameInitial: string;
  id?: string;
  _id?: string;
  imageUrl: string;
  instaFollowers?: number;
  tikTokFollowers?: number;
  instaHandle?: string;
  tikTokHandle?: string;
  rating: number;
  tags: Array<string>;
}
export interface UserProfileSummaryPage {
  rows: Array<UserProfileSummary>;
  currentPage: number;
  lastPage: number;
}

export interface Match {
  id: string;
  _id?: string;
  proposal_id: string;
  beakon_id: string;
  business_id: string;
  beakon_like_id: string;
  business_like_id: string;
  assigned: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface MatchContact extends Match {
  profile: UserProfileSummary;
}

export interface MatchWithProposal extends Match, FullProposals {}


export interface Post {
  _id?: string;
  id?: string;
  proposal_id: string;
  platform: string;
  beakon_id?: string;
  fulfillmentType: string;
  business_id: string;
  state: string;
  compensation: object;
  selection_id?: string;
  selection?: any;
  submissions?: any;
  requestedStartTime: Date;
  requestedEndTime: Date;
  requestedUpTime: number;
  requestedContent?: any;
  endTime: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface Account {
  id: number;
  user_id: string;
  user_type: string;
  earned_total: number;
  charged_total: number;
  payout_count: number;
  charge_count: number;
  last_charge: Date | null;
  last_payout: Date | null;
  payout_schedule: string;
  pending_payout_total: number;
  pending_charge_total: number;
  charge_active: number;
  payout_active: number;
  provider_data: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface BillingMethod {
  id: number;
  user_id: string;
  user_type: string;
  active: number;
  selected: number;
  provider: string;
  provider_data: any;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface PayoutMethod {
  id: number;
  account_id: number;
  user_id: string;
  user_type: string;
  active: number;
  selected: number;
  provider: string;
  provider_data: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface Charge {
  id: number;
  account_id: number;
  user_id: string;
  user_type: string;
  billing_method_id: number;
  amount: number;
  amount_meta: any;
  currency: string;
  complete: number;
  extra: any;
  campaign_id: string;
  provider: string;
  provider_data: any;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface Payout {
  id: number;
  account_id: number;
  user_id: string;
  user_type: string;
  payout_method_id: number;
  charge_id: number;
  amount: number;
  amount_meta: any;
  currency: string;
  provider: string;
  provider_data: any;
  extra: any;
  state: string;
  campaign_id: string;
  task_id: string;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface Ticket {
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  userType: string;
  message: string;
  status: string;
  _id?: string;
  id: string;
}

export interface CampaignBillingSummary {
  chargeTotal: number; // How much the card was charged.
  stripeChargeFee: number; // Stripe's Fee
  paidOut: number; // How much has been paid out included our fees
  inEscrow: number; // How much is eligible to be paid out or refunded.
}

export interface ProcessRefundResponse {
  success: boolean;
  proposal: FullProposals;
  amount: number;
  currency: string;
}

export interface ReviewResponse {
  name: string;
  value: any;
}

export interface Review {
  id?: string;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  review_responses: ReviewResponse[];
  source_user_type: string;
  source_user_id: string;
  proposal_id: string;
}

export type NUMBER_RESPONSE_TYPE = "NUMBER_RESPONSE";
export type TEXT_RESPONSE_TYPE = "TEXT_RESPONSE";

export interface NumberOption {
  name: string;
  label: string;
  value: number;
  selected: boolean;
}
export interface NumberOptions {
  prompt: string;
  name: string;
  options: Array<NumberOption>;
  required: boolean;
  type: NUMBER_RESPONSE_TYPE;
}

export interface TextResponse {
  name: string;
  prompt: string;
  value: string;
  placeholder?: string;
  required: boolean;
  type: TEXT_RESPONSE_TYPE;
}

export interface ProposalChanges {
  title?: string;
  description?: string;
  media?: any;
  tagIds?: any;
}

export interface PostChanges {
  addition: Array<Post>;
  updates: Array<Post>;
  deletion: Array<Post>;
}

export interface ProposalChangesPayload {
  proposal?: ProposalChanges;
  posts?: PostChanges;
}

export interface BeakonSummary {
  id: string;
  firstName: string;
  lastNameInitial: string;
  instaHandle?: string;
  tikTokHandle?: string;
  instaFollowers?: number;
  tikTokFollowers?: number;
  bio?: string;
  tags: Array<string>;
  profilePicture?: string;
  mediaKit?: MediaKit;
}

export interface BeakonSummaryResponse {
  success: boolean;
  summary: BeakonSummary;
}

export interface MediaKit {
  createdAt?: Date;
  updatedAt?: Date;
  media: Array<Media>;
  userId: string;
  userType: string;
  id?: string;
  _id?: string;
  coverLetter: string;
}

export interface Media {
  createdAt?: Date;
  updatedAt?: Date;
  url: string;
  type: mediaType;
  id?: string;
  _id?: string;
  mediaKitId: string;
  fileId: string;
  userId: string;
  userType: string;
}

type mediaType = "image" | "video";

export interface PaginationOptions {
  page: number;
  limit: number;
}

export interface Pagination {
  totalDocs: number;
  totalPages: number;
  limit: number;
  offset: number;
  page: number;
  prevPage?: number | null;
  nextPage?: number | null;
}

export interface PaginationResponse {
  pagination: Pagination;
}

export interface FullProposalsResponse {
  proposals: Array<FullProposals>;
}

export interface FullProposalsResponsePaginated extends PaginationResponse {
  proposals: Array<FullProposals>;
}

export interface MessagesResponsePaginated extends PaginationResponse {
  messages: Array<Message>;
}

export interface MatchesResponsePaginated extends PaginationResponse {
  matches: Array<MatchWithProposal>;
}

export interface InternalVerifyPayload {
  email?: string;
  id?: string;
}

export interface ChatPaginated extends PaginationResponse {
  chats: Array<Chat>;
}

export interface VerifySocialAccountPayload {
  platform: string;
  handle: string;
  code: string;
};

export type InstagramData = {
  inputUrl: string;
  id: string;
  username: string;
  url: string; //https://www.instagram.com/:handle/
  fullName: string;
  biography: string;
  externalUrl: string; //"http://tabs.co/shop-now"
  externalUrlShimmed: string;
  followersCount: number; // 1000000
  followsCount: number;
  hasChannel: boolean;
  highlightReelCount: number;
  isBusinessAccount: boolean;
  joinedRecently: boolean;
  businessCategoryName: string;
  private: boolean;
  verified: boolean;
  profilePicUrl: string;
  profilePicUrlHD: string;
  igtvVideoCount: number;
  latestIgtvVideos?: any[];
  relatedProfiles?: any[];
};

export interface SocialAccount {
  createdAt: Date;
  updatedAt: Date;
  id: string;
  userId: string;
  userType: 'beakon';
  platform: 'instagram';
  handle: string;
  preference: any;
  verified: boolean;
  verifiedMeta?: any;
  hasUpdatedPreferences: boolean;
  apifyData?: InstagramData;
  profilePictureFileId?: string;
};

export interface VerifySocialAccountResponse {
  success: boolean;
  socialAccount?: SocialAccount;
  message: string;
  possibleSocialAccounts: Array<SocialAccount>;
};

export const adminUserType = "admin";

export type BusinessActivities = 'campaign';
export type LedgerActions = 'credit' | 'debit';
export interface LedgerEntry {
  id: number;
  business_activity: BusinessActivities;
  business_activity_id: string;
  account_id: number;
  user_id: string;
  user_type: string;
  action: LedgerActions;
  amount: number;
  currency: string;
  realized: boolean;
  voided: boolean;
  metadata: Record<string, any>;
  createdAt: Date | null;
  updatedAt: Date | null;
};

export type CampaignBusinessActivitySummary = {
  ledgerEntries: LedgerEntry[];
  netRealized: number;
  net: number;
};

export type NotfyResponse = {
  success: boolean;
  message: string;
};

export type ProposalDynamicData = {
  totalCampaignPayout: number;
  campaignTitle: string;
  campaignDescription: string;
  campaignId: string;
  assignedCreator?: any;
  brand: any;
  assignedLastMessage?: Message;
};

export type MatchDynamicData = {
  creator: any;
  brand: any;
  matchId: string;
  lastMessage?: Message;
};

export type DynamicEmailParameters = {
  email: string;
  templateId: string;
  dynamicTemplateData: any;
};
