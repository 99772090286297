import {
  Alert as MuiAlert,
  Button as MuiButton,
  TextField as MuiTextField,
} from "@mui/material";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { spacing } from "@mui/system";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Button = styled(MuiButton)(spacing);

type KeyValuePair = {
  key: string;
  value: string;
};

export const convertPairsToJson = (pairs: KeyValuePair[]) => {
  return pairs.reduce((obj, pair) => {
    if (pair.key) {
      obj[pair.key] = pair.value;
    }
    return obj;
  }, {} as { [key: string]: string });
};

const KeyValueInput = ({
  onChange,
}: {
  onChange: (newPairs: any[]) => void;
}) => {
  const [pairs, setPairs] = useState<KeyValuePair[]>([{ key: "", value: "" }]);

  const handleInputChange = (index: number, event: any) => {
    const { name, value } = event.target;
    const newPairs = [...pairs];
    newPairs[index][name as keyof KeyValuePair] = value; // Add type annotation for the index signature
    setPairs(newPairs);
    onChange(newPairs);
  };

  const handleAddPair = () => {
    setPairs([...pairs, { key: "", value: "" }]);
  };

  const handleRemovePair = (index: number) => {
    const newPairs = pairs.filter((_, i) => i !== index);
    setPairs(newPairs);
    onChange(newPairs);
  };

  return (
    <div>
      {pairs.map((pair, index) => (
        <div key={index} style={{ display: "flex", marginBottom: "8px" }}>
          <TextField
            type="text"
            name="key"
            placeholder="Key"
            value={pair.key}
            onChange={(e) => handleInputChange(index, e)}
            style={{ marginRight: "8px" }}
          />
          <TextField
            type="text"
            name="value"
            placeholder="Value"
            value={pair.value}
            onChange={(e) => handleInputChange(index, e)}
            style={{ marginRight: "8px" }}
          />
          <Button variant={"outlined"} type="button" onClick={() => handleRemovePair(index)}>
            Remove
          </Button>
        </div>
      ))}
      <Button variant={"outlined"} type="button" onClick={handleAddPair}>
        Add Pair
      </Button>
    </div>
  );
};

export default KeyValueInput;
