import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
    Button as MuiButton,
    CardContent,
    Grid,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    TextField as MuiTextField,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow
} from "@mui/material";
import { spacing } from "@mui/system";
import useDispatch from "../../hooks/useAppDispatch";
import useSelector from "../../hooks/useAppSelector";

import {
    fetchCampaignLedger,
    getCampaignLedger,
} from "../../redux/slices/accounting";
import { CampaignBusinessActivitySummary } from "../../sdk/Types";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const formatMoney = (amount: number) => {
    return `$${(amount / 100).toFixed(2)}`;
}

const CampaignLedger = ({ campaignLedger: {
    net,
    netRealized,
    ledgerEntries,
} }: {
    campaignLedger: CampaignBusinessActivitySummary
}) => {
    return (
        <>
            <Typography variant="h6" gutterBottom>
                Campaign Ledger
            </Typography>
            <Typography variant="body1" gutterBottom>
                {`Net: ${formatMoney(net)}`}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {`Net Realized: ${formatMoney(netRealized)}`}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Created
                        </TableCell>
                        <TableCell>
                            Last Updated
                        </TableCell>
                        <TableCell>
                            Is Realized
                        </TableCell>
                        <TableCell>
                            Is Voided
                        </TableCell>
                        <TableCell>
                            Amount
                        </TableCell>
                        <TableCell>
                            Type
                        </TableCell>
                        <TableCell>
                            User ID
                        </TableCell>
                        <TableCell>
                            User Type
                        </TableCell>
                        <TableCell>
                            Business Activity ID
                        </TableCell>
                        <TableCell>
                            Business Activity Type
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        ledgerEntries.map((ledgerEntry, index) => 
                            <TableRow key={index}>
                                <TableCell>
                                    {ledgerEntry.createdAt}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.updatedAt}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.realized.toString()}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.voided.toString()}
                                </TableCell>
                                <TableCell>
                                    {formatMoney(ledgerEntry.amount)}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.action}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.user_id}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.user_type}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.business_activity_id}
                                </TableCell>
                                <TableCell>
                                    {ledgerEntry.business_activity}
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>
    );
};

const CampaignLedgerCard = () => {
    const [campaignId, setCampaignId] = React.useState("");
    const dispatch = useDispatch();
    const campaignLedger = useSelector(getCampaignLedger);
    useEffect(() => {
        dispatch(fetchCampaignLedger(campaignId));
    }, [dispatch]);
    return (
        <Card mb={6}>
            <CardContent>
                <Grid container mb={4}>
                    <Grid item xs={12}>
                        <Typography variant="h4" mb={4} gutterBottom>
                            Campaign Ledger
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            label={'Campaign Id'}
                            value={campaignId}
                            onChange={(e) => setCampaignId(e.target.value)}
                        />

                    </Grid>
                    <Grid item xs={12}
                        mt={2}
                    >
                        <Button
                            variant="contained"
                            onClick={() => dispatch(fetchCampaignLedger(campaignId))}
                        >
                            Fetch Ledger
                        </Button>
                    </Grid>
                </Grid>

                <Grid container mb={4}>
                    <Grid item xs={12}>
                        {
                            campaignLedger && <CampaignLedger campaignLedger={campaignLedger} />
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const Accounting = () => {
    return (
        <>
            <Helmet title="Accounting" />
            <Typography variant="h3" gutterBottom display="inline">
                Accounting
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link color="inherit" href="/dashboard">
                    Dashboard
                </Link>
                <Typography color="textPrimary">Accounting</Typography>
            </Breadcrumbs>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <CampaignLedgerCard />
                </Grid>
            </Grid>
        </>
    );
};

export default Accounting;