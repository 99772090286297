import { Admin } from "./Admin";
declare global {
    interface Window {
      USERS_URL: string,
      PROPOSALS_URL: string,
      PAYMENTS_URL: string,
    }
}
const usePrivate = false;
const localHost = usePrivate ? '10.88.111.31' : '0.0.0.0'

const remoteServiceConfig = {
    users: {
        url: 'https://app.beakongage.com/users',
    },
    proposals: {
        url: 'https://app.beakongage.com/proposals',
    },
    payments: {
        url: 'https://app.beakongage.com/payments',
    },
};

const localServiceConfig = {
    users: {
        url: `http://${localHost}:5000/users`
    },
    proposals: {
        url: `http://${localHost}:4000/proposals`,
    },
    payments: {
        url: `http://${localHost}:4500/payments`,
    },
};

const useLocal = false;
let serviceConfig;
if (useLocal) {
    serviceConfig = localServiceConfig;
} else {
    serviceConfig = remoteServiceConfig;
}

const admin = new Admin(
    serviceConfig.users.url,
    serviceConfig.proposals.url,
    serviceConfig.proposals.url,
    serviceConfig.payments.url
);

export default admin;
