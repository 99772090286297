import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ReactJson from "react-json-view";

import useDispatch from "../../hooks/useAppDispatch";
import useSelector from "../../hooks/useAppSelector";
import {
  campaignLinkSlice,
  createCampaignLink,
  getBeakonId,
  getCampaignId,
} from "../../redux/slices/campaignlink";
import {
  getProposalId,
  getCanFetch,
  emailNotificationSlice,
  getIsFetching,
  fetchProposalInformation,
  getProposalDynamicData,
  getMatchesDynamicData,
  getTemplateId,
  getEmail,
  getSubject,
  sendEmail,
  getCanSendEmail,
} from "../../redux/slices/emailnotification";
import KeyValueInput, { convertPairsToJson } from "../components/KeyValue";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Information = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Marketing Tools
        </Typography>
        <Typography variant="body2" gutterBottom>
          Add things here to make marketing easier.
        </Typography>
      </CardContent>
    </Card>
  );
};

const DynamicData = () => {
  const proposalId = useSelector(getProposalId);
  const isFetching = useSelector(getIsFetching);
  const proposalDynamicData = useSelector(getProposalDynamicData);
  const matchesDynamicData = useSelector(getMatchesDynamicData);
  if (!proposalDynamicData || !matchesDynamicData || isFetching) {
    return null;
  }
  return (
    <Grid container mb={4}>
      <Grid item>
        <Typography variant="h6" marginY={2} gutterBottom>
          Campaign Dynamic Data
        </Typography>
        <ReactJson collapsed={2} src={proposalDynamicData} />
        <Typography variant="h6" marginY={2} gutterBottom>
          Matches Dynamic Data
        </Typography>
        {matchesDynamicData.map((match, index) => {
          return <ReactJson collapsed={2} key={match.matchId} src={match} />;
        })}
      </Grid>
    </Grid>
  );
};

const SendGridEmailCard = () => {
  const dispatch = useDispatch();
  const proposalId = useSelector(getProposalId);
  const canFetch = useSelector(getCanFetch);
  const canSendEmail = useSelector(getCanSendEmail);
  const isFetching = useSelector(getIsFetching);
  const handlePairsChange = (newPairs: any[]) => {
    const json = convertPairsToJson(newPairs);
    dispatch(emailNotificationSlice.actions.setTemplateData(json));
  };
  const templateId = useSelector(getTemplateId);
  const email = useSelector(getEmail);
  const subject = useSelector(getSubject);
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Send a campaign email
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography marginY={2} variant="body2" gutterBottom>
              Use this to find dynamic data that can be used inside a sendgrid
              email template.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Campaign ID"
              value={proposalId}
              onChange={(e) => {
                dispatch(
                  emailNotificationSlice.actions.setProposalId(e.target.value)
                );
              }}
              disabled={isFetching}
              style={{
                marginBottom: 10,
                marginTop: 10,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{
                marginTop: 5,
                marginBottom: 5,
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(fetchProposalInformation());
              }}
              disabled={!canFetch}
            >
              Fetch dynamic data
            </Button>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography variant="h4" gutterBottom>
              Email Custom Variables
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              These variables can be used in the email template. For example in
              your template if your have the following variable:{" "}
              {"{{firstName}}"}. You can add a key value pair with key:
              firstName and value: John.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DynamicData />
          </Grid>
          <Grid item xs={12}>
            <DynamicData />
          </Grid>

          <Grid item xs={12}>
            <KeyValueInput onChange={handlePairsChange} />
          </Grid>

		  <Grid item xs={12} mt={4}>
			  <Typography variant="h4" gutterBottom>
				  Email Information
			  </Typography>
		  </Grid>

		  <Grid item xs={12}>
			  <Typography variant="body2" gutterBottom>
				  Use this to send an email to a user. Make sure you have the correct template ID and the subject in sendgrid is {"{{subject}}"}
			  </Typography>
		  </Grid> 			

          <Grid item xs={12}>
            <TextField
              label="Subject"
              value={subject}
              onChange={(e) => {
                dispatch(
                  emailNotificationSlice.actions.setSubject(e.target.value)
                );
              }}
              disabled={isFetching}
              style={{
                marginRight: 10,
              }}
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => {
                dispatch(
                  emailNotificationSlice.actions.setEmail(e.target.value)
                );
              }}
              disabled={isFetching}
              style={{
                marginRight: 10,
              }}
            />
            <TextField
              label="Sendgrid Template ID"
              value={templateId}
              onChange={(e) => {
                dispatch(
                  emailNotificationSlice.actions.setTemplateId(e.target.value)
                );
              }}
              disabled={isFetching}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ marginTop: 5 }}
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(sendEmail());
              }}
              disabled={!canSendEmail}
            >
              Send Email
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const DirectEmailMarketingLink = () => {
  const [email, setEmail] = useState("");
  const [encodedSource, setEncodedSource] = useState("");
  const baseMarketingLink = "https://beakongage.com/creators";
  const encryptionKey = "SuperSayianGodSuperSayian";
  const genCompleteLink = (base: string, email: string): string => {
    try {
      const encryptedEmail = xorEncryptDecrypt(email, encryptionKey);
      const encoded = btoa(encryptedEmail);
      return `${base}?link_source=${encoded}`;
    } catch (e) {
      console.error(e);
      return "N/A";
    }
  };
  const decodeQueryParam = (param: string): string => {
    try {
      const decoded = atob(param);
      return xorEncryptDecrypt(decoded, encryptionKey);
    } catch (e) {
      console.error(e);
      return "N/A";
    }
  };
  const xorEncryptDecrypt = (text: string, key: string): string => {
    let result = "";
    for (let i = 0; i < text.length; i++) {
      result += String.fromCharCode(
        text.charCodeAt(i) ^ key.charCodeAt(i % key.length)
      );
    }
    return result;
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Custom Email Marketing Link For Influencers
            </Typography>
            <Typography>
              You can use this link to send to influencers directly. It will
              automatically send the open events to google analytics. So we can
              track who opened or not.
            </Typography>
            <Typography>{baseMarketingLink}</Typography>
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <TextField
              label={"Target Email"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Typography>{genCompleteLink(baseMarketingLink, email)}</Typography>
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <TextField
              label={"Encoded source"}
              value={encodedSource}
              onChange={(e) => {
                setEncodedSource(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Typography>{decodeQueryParam(encodedSource)}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

function Marketing() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(campaignLinkSlice.actions.reset());
    };
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Marketing
      </Typography>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Information />
          <SendGridEmailCard />
          <DirectEmailMarketingLink />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Marketing;
