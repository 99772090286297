import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { FullProposals, MatchContact, MatchDynamicData, ProposalDynamicData } from '../../sdk/Types';
import { RootState } from '../store';
import admin from '../../sdk';

// Define the initial state
interface EmailNotificationState {
    proposalId: string;
    isFetching: boolean;
    proposalDynamicData?: ProposalDynamicData
    matchesDynamicData?: MatchDynamicData[];
    templateData: any;
    templateId: string;
    email: string;
    subject: string;
}

const initialState: EmailNotificationState = {
    // Set initial values for your state properties here
    proposalId: '',
    isFetching: false,
    templateData: {},
    templateId: '',
    email: '',
    subject: '',
};

export const getProposalId = (state: RootState) => 
    state.emailnotification.proposalId;

export const getIsFetching = (state: RootState) =>
    state.emailnotification.isFetching;

export const getProposalDynamicData = (state: RootState) =>
    state.emailnotification.proposalDynamicData;

export const getMatchesDynamicData = (state: RootState) =>
    state.emailnotification.matchesDynamicData;

export const getCanFetch = (state: RootState) => {
    return getProposalId(state) !== '';
}

export const getTemplateId = (state: RootState) =>
    state.emailnotification.templateId;

export const getTemplateData = (state: RootState) =>
    state.emailnotification.templateData;

export const getEmail = (state: RootState) =>
    state.emailnotification.email;

export const getSubject = (state: RootState) =>
    state.emailnotification.subject;

export const getCanSendEmail = (state: RootState) => {
    return (
        getSubject(state)!== '' 
        && getTemplateId(state) !== '' 
        && getEmail(state) !== ''
    );
}

// Create the emailnotification slice
export const emailNotificationSlice = createSlice({
    name: 'emailnotification',
    initialState,
    reducers: {
        // Define the reducers here
        setIsFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload;
        },
        setProposalDynamicData: (state, action: PayloadAction<ProposalDynamicData>) => {
            state.proposalDynamicData = action.payload;
        },
        setMatchesDynamicData: (state, action: PayloadAction<MatchDynamicData[]>) => {
            state.matchesDynamicData = action.payload;
        },
        setProposalId: (state, action: PayloadAction<string>) => {
            state.proposalId = action.payload;
        },
        setTemplateData: (state, action: PayloadAction<any>) => {
            state.templateData = action.payload;
        },
        setTemplateId: (state, action: PayloadAction<string>) => {
            state.templateId = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setSubject: (state, action: PayloadAction<string>) => {
            state.subject = action.payload;
        },
    },
});

export const fetchProposalInformation = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            // Fetch the proposal information here
            const proposalId = getProposalId(getState());
            const proposalDynamicData = await admin.getProposalEmailDynamicData(proposalId);
            const { matches } = await admin.getProposalMatches(proposalId);
            const matchesDynamicData = await Promise.all(
                matches.map(async (match) => {
                    return await admin.getMatchEmailDynamicData(match.id, proposalId);
                })
            );

            dispatch(emailNotificationSlice.actions.setProposalDynamicData(proposalDynamicData));
            dispatch(emailNotificationSlice.actions.setMatchesDynamicData(matchesDynamicData));
        } finally {
            dispatch(emailNotificationSlice.actions.setIsFetching(false));
        }
    };
}

export const sendEmail = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const state = getState();
            // Send the email here
            const templateId = getTemplateId(state);
            const templateData = getTemplateData(state);
            const email = getEmail(state);
            const subject = getSubject(state);
            await admin.sendDynamicEmail({
                templateId,
                dynamicTemplateData: {
                    ...templateData,
                    subject,
                },
                email,
            });
            alert('Email sent successfully');
        } catch (e) {
            console.error(e);
            alert('Failed to send email');
        } finally {
            dispatch(emailNotificationSlice.actions.setIsFetching(false));
        }
    };

}

// Export the actions and reducer
export default emailNotificationSlice.reducer;