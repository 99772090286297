import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import usersSliceReducer from "./slices/users";
import campaignlinkReducer from "./slices/campaignlink";
import campaigntasksReducer from "./slices/campaigntasks";
import payoutsReducer from "./slices/payouts";
import accountingReducer from './slices/accounting';
import emailnotificationReducer from './slices/emailnotification';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    users: usersSliceReducer,
    campaignlink: campaignlinkReducer,
    campaigntasks: campaigntasksReducer,
    payouts: payoutsReducer,
    accounting: accountingReducer,
    emailnotification: emailnotificationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
