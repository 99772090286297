import axios, { AxiosRequestConfig } from "axios";
import { adminUserType, BusinessModel, CampaignBusinessActivitySummary, DynamicEmailParameters, InternalVerifyPayload, MatchDynamicData, MatchesResponsePaginated, NotfyResponse, Pagination, PaginationOptions, Payout, Post, ProposalDynamicData, SocialAccount, UserModel, VerifySocialAccountPayload, VerifySocialAccountResponse } from "./Types";

export class Admin {
  userObject: any;
  token: string = "";
  usersApi: string;
  proposalsApi: string;
  paymentsApi: string;
  filesApi: string;
  userType: string = adminUserType;
  constructor(usersApi: string, proposalsApi: string, filesApi: string, paymentsApi: string) {
    this.usersApi = usersApi;
    this.proposalsApi = proposalsApi;
    this.filesApi = filesApi;
    this.paymentsApi = paymentsApi;
  }
  accessHttpHeaders() {
    return { "x-api-token": this.token };
  }
  httpHeaders() {
    return {
      "Content-Type": "application/json",
      ...this.accessHttpHeaders(),
    };
  }

  async verifyToken(token: string) {
    this.token = token;
    const config: any = {
      url: `${this.usersApi}/api/admin/verify`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    const userObject = response.data;
    this.userObject = userObject;
    return userObject;
  }

  async login(email: string, password: string) {
    const config: any = {
      url: `${this.usersApi}/api/admin/login`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({
        email,
        password,
      }),
    };
    const response: any = await axios(config);
    const { userObject, token } = response.data;
    this.userObject = userObject;
    this.token = token;
    return userObject;
  }

  async verifyTask(taskId: string, submissionId: string) {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/posts/${taskId}/submissions/${submissionId}/verify`,
      method: "post",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async rejectTaskVerification(taskId: string, submissionId: string, reason: string) {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/posts/${taskId}/submissions/${submissionId}/reject`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({
        reason,
      }),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getTasksAwaitingVerification(paginationOptions: PaginationOptions):  Promise<{posts: Post[], pagination: Pagination}> {
    const params: any = {
      limit: paginationOptions.limit,
      page: paginationOptions.page,
    };
    const qs = '?' + new URLSearchParams(params).toString();
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/posts/awaiting-verification${qs}`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  // Will make each other show up in their feed even if there is not overlap
  async connectBeakonToCampaign(proposalId: string, beakonId: string, notify: boolean = true) {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/proposals/${proposalId}/beakons/${beakonId}/connect`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({
        notify,
      }),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async matchBeakonToCampaign(proposalId: string, beakonId: string, notify: boolean = true) {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/proposals/${proposalId}/beakons/${beakonId}/match`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({
        notify,
      }),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async syncChatForBeakonAndBusiness(beakonId: string, businessId: string) {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/chat/sync`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({
        beakonId,
        businessId,
      }),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async payout(payoutId: number) {
    const config: any = {
      url: `${this.paymentsApi}/api/web/admin/pending-payouts/${payoutId}/pay`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({
      }),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getPendingPayouts(paginationOptions: PaginationOptions): Promise<{payouts: Payout[], pagination: Pagination}> {
    const params: any = {
      limit: paginationOptions.limit,
      page: paginationOptions.page,
    };
    const qs = '?' + new URLSearchParams(params).toString();
    const config: any = {
      url: `${this.paymentsApi}/api/web/admin/pending-payouts${qs}`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async verifyBeakon(payload: InternalVerifyPayload) {
    const config: any = {
      url: `${this.usersApi}/api/admin/verify/user`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify(payload),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async verifyBusiness(payload: InternalVerifyPayload) {
    const config: any = {
      url: `${this.usersApi}/api/admin/verify/business`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify(payload),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async linkStripeTransferToPayout(payoutId: number, stripeTransferId: string) {
    const config: any = {
      url: `${this.paymentsApi}/api/web/admin/link-payout/${payoutId}/stripe-transfer/${stripeTransferId}`,
      method: "post",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getBeakons(filter: string, paginationOptions: PaginationOptions) {
    const params: any = {
      limit: paginationOptions.limit,
      page: paginationOptions.page,
    };
    const qs = '?' + new URLSearchParams(params).toString();
    const config: any = {
      url: `${this.usersApi}/api/admin/beakons/${filter}${qs}`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getBusinesses(filter: string, paginationOptions: PaginationOptions) {
    const params: any = {
      limit: paginationOptions.limit,
      page: paginationOptions.page,
    };
    const qs = '?' + new URLSearchParams(params).toString();
    const config: any = {
      url: `${this.usersApi}/api/admin/businesses/${filter}${qs}`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getBeakonAuth(beakonId: string) {
    const config: any = {
      url: `${this.usersApi}/api/admin/beakon/${beakonId}/auth`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getBusinessAuth(businessId: string) {
    const config: any = {
      url: `${this.usersApi}/api/admin/business/${businessId}/auth`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async verifyInstagramSocialAccount(handle: string, verificationCode: string): Promise<VerifySocialAccountResponse> {
    const config: any = {
      url: `${this.usersApi}/api/admin/verify/social-account`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({
        code: verificationCode,
        handle,
        platform: 'instagram',
      } as VerifySocialAccountPayload),
    };
    const response: any = await axios(config);
    return response.data;
  }

  /**
   * Will not enrich the social account
   * @param socialAccountId
   * @returns 
   */
  async verifySocialAccountBasic(socialAccountId: string): Promise<VerifySocialAccountResponse> {
    const config: any = {
      url: `${this.usersApi}/api/admin/social-account/${socialAccountId}/verify`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({}),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getCampaignLedger(proposalId: string): Promise<CampaignBusinessActivitySummary> {
    const config: any = {
      url: `${this.paymentsApi}/api/web/admin/ledger/campaign/${proposalId}/summary`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async enrichSocialAccount(socialAccountId: string): Promise<SocialAccount> {
    const config: any = {
      url: `${this.usersApi}/api/admin/social-account/${socialAccountId}/enrich`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify({}),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getBeakonByEmail(email: string): Promise<UserModel> {
    const qs = '?' + new URLSearchParams({ email }).toString();
    const config: any = {
      url: `${this.usersApi}/api/admin/beakon/${qs}`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data.userObject;
  }

  async getBusinessByEmail(email: string): Promise<{
    business: BusinessModel,
    success: boolean,
  }> {
    const qs = '?' + new URLSearchParams({ email }).toString();
    const config: any = {
      url: `${this.usersApi}/api/admin/business/${qs}`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getBeakonMatches(beakonId: string, paginationOptions: PaginationOptions): Promise<MatchesResponsePaginated> {
    const params: any = {
      limit: paginationOptions.limit,
      page: paginationOptions.page,
    };
    const qs = '?' + new URLSearchParams(params).toString();
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/beakon/${beakonId}/matches${qs}`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async notifyBeakonOfMatch(matchId: string): Promise<NotfyResponse> {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/proposals/matches/${matchId}/notify-beakon`,
      method: "post",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async notifyBeakonRespondAssignedCampaign(campaignId: string): Promise<NotfyResponse> {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/proposals/${campaignId}/notify-assigned-expiring-soon`,
      method: "post",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }


  async getProposalMatches(campaignId: string): Promise<MatchesResponsePaginated> {
    const config: any = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/proposals/${campaignId}/matches`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response: any = await axios(config);
    return response.data;
  }

  async getProposalEmailDynamicData (proposalId: string): Promise<ProposalDynamicData> {
    const config: AxiosRequestConfig = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/proposals/${proposalId}/email-dynamic-data`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response = await axios(config);
    return response.data;
  }

  async getMatchEmailDynamicData (matchId: string, proposalId: string): Promise<MatchDynamicData> {
    const config: AxiosRequestConfig = {
      url: `${this.proposalsApi}/api/proposals/admin/${this.userObject.id}/proposals/${proposalId}/matches/${matchId}/email-dynamic-data`,
      method: "get",
      headers: this.httpHeaders(),
    };
    const response = await axios(config);
    return response.data;
  }

  async sendDynamicEmail(
    payload: DynamicEmailParameters
  ): Promise<any> {
    const config: any = {
      url: `${this.usersApi}/api/admin/send-dynamic-email`,
      method: "post",
      headers: this.httpHeaders(),
      data: JSON.stringify(payload),
    };
    const response: any = await axios(config);
    return response.data;
  }

}
