import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import admin from "../../sdk";
import { CampaignBusinessActivitySummary } from '../../sdk/Types';

export interface AccountingState {
    isFetchingCampaignLedger: boolean;
    campaignLedger?: CampaignBusinessActivitySummary;
    errorFetchingCampaignLedger: boolean;
};

const initialState: AccountingState = {
    isFetchingCampaignLedger: false,
    errorFetchingCampaignLedger: false,
};

export const getIsFetchingCampaignLedger = (state: any) => state.accounting.isFetchingCampaignLedger;
export const getCampaignLedger = (state: any) => state.accounting.campaignLedger;
export const getErrorFetchingCampaignLedger = (state: any) => state.accounting.errorFetchingCampaignLedger;

export const accountingSlice = createSlice({
    name: "accounting",
    initialState,
    reducers: {
        setIsFetchingCampaignLedger: (state, action: PayloadAction<boolean>) => {
            state.isFetchingCampaignLedger = action.payload;
        },
        setCampaignLedger: (state, action: PayloadAction<CampaignBusinessActivitySummary>) => {
            state.campaignLedger = action.payload;
        },
        setErrorFetchingCampaignLedger: (state, action: PayloadAction<boolean>) => {
            state.errorFetchingCampaignLedger = action.payload;
        },
        reset: (state) => {
            state.isFetchingCampaignLedger = false;
            state.errorFetchingCampaignLedger = false;
        }
    },
});

export const fetchCampaignLedger = (campaignId: string) => async (dispatch: any) => {
    try {
        dispatch(accountingSlice.actions.setIsFetchingCampaignLedger(true));
        const ledger = await admin.getCampaignLedger(campaignId);
        dispatch(accountingSlice.actions.setCampaignLedger(ledger));
    } catch (e) {
        dispatch(accountingSlice.actions.setErrorFetchingCampaignLedger(true));
    } finally {
        dispatch(accountingSlice.actions.setIsFetchingCampaignLedger(false));
    }
};

export default accountingSlice.reducer;
